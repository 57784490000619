import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { orderBy } from 'lodash';
import { withFormik, FormikProps } from 'formik';
import { object, string } from 'yup';
import clsx from 'clsx';
// Components
import {
  Toolbar,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Divider,
  FormHelperText
} from '@material-ui/core';
import { Add, Save, Close } from '@material-ui/icons';
import { LoaderOverlay } from '@shared/components/loader';
import { PageTitle } from '@shared/components/layout';
// Types
import { IEmployeeRate } from '@shared/types';

interface IEmployeeRolesForm {
  initialValues: IEmployeeRate | {};
  rolesList: IEmployeeRate[];
  onCancel: () => void;
  onSave: (values: IEmployeeRate, callBack: (error?: Error) => void) => void;
}

export const EmployeeRolesForm: FC<IEmployeeRolesForm & FormikProps<IEmployeeRate>> = ({
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  errors,
  touched,
  submitForm,
  onCancel,
  isSubmitting,
  rolesList
}) => {
  const classes = useStyles();

  return (
    <>
      <Toolbar disableGutters>
        <PageTitle title={values.employeeRateId !== -1 ? 'Edit Role' : 'Add Role'} />
        <div className={classes.buttonWrapper}>
          <Button size='small' color='secondary' startIcon={values.employeeRateId ? <Save /> : <Add />} onClick={() => submitForm()}>
            {values.employeeRateId !== -1 ? 'Save Role' : 'Add Role'}
          </Button>
          <Button size='small' color='inherit' startIcon={<Close />} onClick={() => onCancel()}>
            Cancel
          </Button>
        </div>
      </Toolbar>
      <Divider className={classes.divider} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Card elevation={0} variant='outlined' className={clsx(classes.marginBottom, classes.showOverflow)}>
            <CardHeader
              title='Role Information'
              className={classes.primaryHeader}
              titleTypographyProps={{ component: 'h2', className: 'fontWeight-normal' }}
            />
            <CardContent>
              <div className={classes.column}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Role'
                  name='title'
                  required
                  value={values.title}
                  onChange={handleChange}
                  onBlur={e => {
                    handleBlur(e);
                  }}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                  autoFocus
                />
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel id='employee-status-label' className={classes.outlinedLabel}>
                    Status
                  </InputLabel>
                  <Select
                    name='employeeStatus'
                    labelId='employee-status-label'
                    id='employee-status'
                    value={+values.isActive}
                    onChange={({ target: { value } }) => setFieldValue('isActive', Boolean(value) as boolean)}
                    onBlur={handleBlur}
                    error={Boolean(touched.isActive && errors.isActive)}
                  >
                    <MenuItem value={1}>Active</MenuItem>
                    <MenuItem value={0}>Inactive</MenuItem>
                  </Select>
                  {touched.isActive && errors.isActive && <FormHelperText>{errors.isActive}</FormHelperText>}
                </FormControl>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <LoaderOverlay open={isSubmitting} />
    </>
  );
};

const EmployeeSchema = object().shape({
  title: string().nullable().required('Title is required.')
});

export default withFormik<IEmployeeRolesForm, IEmployeeRate>({
  enableReinitialize: true,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: ({ initialValues = {} }) => {
    return {
      employeeRateId: -1,
      title: '',
      isActive: true,
      dateCreated: new Date('0001-01-01T00:00:00Z'),
      dateUpdated: new Date('0001-01-01T00:00:00Z'),
      ...initialValues,
      rolesList: (initialValues as IEmployeeRate) ? orderBy(initialValues as IEmployeeRate, ['employeeRateId'], ['desc']) : []
    };
  },
  validationSchema: EmployeeSchema,
  handleSubmit: (values, { props: { onSave }, setSubmitting, resetForm }) => {
    setSubmitting(true);
    onSave(
      {
        ...values
      },
      (error?: Error) => {
        if (error) {
          setSubmitting(false);
        } else {
          setSubmitting(false);
          resetForm(); // allows for the dirty flag to reset
        }
        Promise.resolve();
      }
    );
  }
})(EmployeeRolesForm);

const useStyles = makeStyles(theme => ({
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1)
  },
  secondaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  buttonToolbar: {
    '& > button': {
      marginRight: theme.spacing(1)
    }
  },
  delete: {
    color: theme.palette.error.main
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(1)
    }
  },
  checkboxRoot: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(0.5),
    width: '8rem'
  },
  descriptionMultiline: {
    height: theme.spacing(8),
    '& textarea': {
      height: '100%'
    }
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  budgetInformationGrid: {
    '& > div': {
      minWidth: '20%'
    }
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  actionButton: {
    padding: 0
  },
  addButton: {
    marginLeft: 'auto'
  },
  editing: {
    display: 'none'
  },
  mobileTable: {
    padding: 0
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  showOverflow: {
    overflow: 'visible'
  },
  buttonWrapper: {
    marginLeft: 'auto'
  },
  numeric: {
    '& input': {
      textAlign: 'left'
    }
  },
  newRatesWrapper: {
    display: 'flex',
    width: '104%',
    '& input': {
      padding: 4
    },
    '& p': {
      width: '10%',
      padding: 4,
      'margin-top': '15px'
    }
  }
}));
