import React, { FC, useMemo, useRef } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { withFormik, FormikProps } from 'formik';
import { object, string, date, number } from 'yup';
// Components
import {
  Modal,
  Fade,
  Backdrop,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  GridList,
  GridListTile,
  FormControl,
  TextField,
  Button
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DateRangePicker, DateRange } from '@shared/components/inputs';
// Types
import { IAppState, ITimeSheetRange } from '@shared/types';
// Constants
import { CLIENT_PROJECT_STATUSES } from '@shared/constants';

interface ITimeSheetRangeFormValues {
  dateRange: DateRange | null;
  description: string;
  clientProject: { label: string; value: number } | null;
}

interface IMultiDayAddProps {
  open: boolean;
  close: () => void;
  onSave: (bulkEntry: ITimeSheetRange, callback: (error?: Error) => void) => void;
}

const MultiDayAdd: FC<IMultiDayAddProps & FormikProps<ITimeSheetRangeFormValues>> = ({
  open,
  close,
  setFieldValue,
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  submitForm,
  resetForm
}) => {
  const classes = useStyles();
  const { clients, clientProjectsList } = useSelector((state: IAppState) => state.clients);

  // disable button on save
  const buttonRef = useRef(null);

  const mercuryProjects = useMemo(() => {
    const mercuryWorksClient = clients.find(client => client.name.toLowerCase() === 'mercuryworks');
    if (mercuryWorksClient) {
      const filteredProjectList = clientProjectsList.filter(
        project => project.clientId === mercuryWorksClient.clientId && project.projectStatus === CLIENT_PROJECT_STATUSES.APPROVED
      );
      return filteredProjectList.sort((a, b) => (a.projectName > b.projectName ? 1 : -1));
    }
    return [];
  }, [clients, clientProjectsList]);

  return (
    <Modal
      aria-labelledby='multi-day-add-modal-total'
      aria-describedby='multi-day-add-modal-description'
      className={classes.modal}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Card className={classes.root}>
          <CardHeader title='Add New Time Entry' />
          <CardContent>
            <GridList cellHeight='auto' cols={1} spacing={24}>
              <GridListTile cols={1}>
                <FormControl fullWidth>
                  <DateRangePicker
                    autoOk
                    label='Dates'
                    value={values?.dateRange || undefined}
                    onChange={values => {
                      setFieldValue('dateRange', values);
                    }}
                    format='M/d/yyyy'
                    error={(errors.dateRange && touched.dateRange) as boolean}
                    helperText={touched.dateRange && errors.dateRange}
                  />
                </FormControl>
              </GridListTile>
              <GridListTile cols={1}>
                <FormControl fullWidth>
                  <Autocomplete
                    id='mercury-projects-autocomplete'
                    options={mercuryProjects.map(x => ({ label: x.projectName, value: x.clientProjectId }))}
                    getOptionLabel={option => option.label}
                    value={values.clientProject}
                    onChange={(e: any, value: any) => setFieldValue('clientProject', value)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Project'
                        InputLabelProps={{ id: 'mercury-project-autocomplete-label', htmlFor: 'mercury-project-autocomplete' }}
                        error={(errors.clientProject && touched.clientProject) as boolean}
                        helperText={touched.clientProject && errors.clientProject}
                      />
                    )}
                  />
                </FormControl>
              </GridListTile>
              <GridListTile cols={1}>
                <FormControl fullWidth>
                  <TextField
                    label='Description'
                    id='description-text-field'
                    defaultValue={values.description}
                    name='description'
                    error={(errors.description && touched.description) as boolean}
                    helperText={touched.description && errors.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </GridListTile>
            </GridList>
          </CardContent>
          <CardActions>
            <Button
              variant='contained'
              color='secondary'
              ref={buttonRef}
              onClick={() => {
                // this was the onnly way to disabled. I attempted to tie into
                // disabled={isSubmitting} from formik, also did internal state
                // management, annd even tried to store it in values and call
                // setFieldValue('isSubmitting', true) but the button was
                // clickable regardless of the disabled state. So be a little
                // more forceful so we don't multi click the save
                if (buttonRef && buttonRef.current && buttonRef.current) {
                  if (!(buttonRef.current as any).disabled) {
                    submitForm();
                  }
                  (buttonRef.current as any).setAttribute!('disabled', true);
                }
              }}
            >
              Save
            </Button>
            <Button
              type='button'
              variant='contained'
              color='default'
              onClick={() => {
                resetForm();
                close();
              }}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  );
};

const MultiDayAddSchema = object().shape({
  dateRange: object()
    .shape({
      begin: date(),
      end: date()
    })
    .nullable()
    .required('Date Range is required'),
  clientProject: object()
    .shape({
      label: string(),
      value: number()
    })
    .nullable()
    .required('Project is required'),
  description: string().nullable().required('Description is required')
});

export default withFormik<IMultiDayAddProps, ITimeSheetRangeFormValues>({
  enableReinitialize: true,
  mapPropsToValues: () => {
    return {
      dateRange: null,
      clientProject: null,
      description: ''
    };
  },
  validationSchema: MultiDayAddSchema,
  handleSubmit: async (values, { resetForm, props: { onSave } }) => {
    const adjustedValues: ITimeSheetRange = {
      startDate: values.dateRange!.begin!.toISOString(),
      endDate: values.dateRange!.end!.toISOString(),
      description: values.description,
      clientProjectId: values.clientProject!.value
    };

    onSave(adjustedValues, error => {
      if (!error) {
        resetForm();
      }
    });
  }
})(MultiDayAdd);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    root: {
      width: 300,
      [theme.breakpoints.up('md')]: {
        width: 450
      }
    }
  })
);
