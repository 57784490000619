import React, { FC, useMemo, useState } from 'react';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Box, Stepper, Step, StepLabel, StepConnector, Typography, useMediaQuery } from '@material-ui/core';
import { stepDefinition } from '@src/employees/containers/SoftwareReleaseDetail/types';
import { useHistory, useParams } from 'react-router-dom';
import { ConfirmationModal } from '../../../employees/components/modals';

interface IProgressStepper {
  hasTitle?: boolean;
  title?: string;
  titleFontSize?: string;
  titleColor?: string;
  titleFontWeight?: number;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  hasPendingChangesDate?: boolean;
}

interface StepIconProps {
  icon: React.ReactElement;
  active: boolean;
  completed: boolean;
  isFirst: boolean;
}

export const ProgressStepper: FC<IProgressStepper> = ({
  title,
  titleFontSize = '1rem',
  titleColor = '#616161',
  titleFontWeight = 600,
  hasTitle = true,
  activeStep,
  setActiveStep,
  hasPendingChangesDate
}) => {
  const history = useHistory();
  const { releaseId }: { releaseId: string } = useParams();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const steps = stepDefinition;
  const actualReleaseId = releaseId ?? 'add';

  // Modal to notify of unsaved changes //
  const [nextStep, setNextStep] = useState<number | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleStepperClick = (index: number) => {
    const currentStep = releaseStepLinks[activeStep]; // Get the current step
    const targetStep = releaseStepLinks[index]; // Get the target step

    if (currentStep?.url.includes('/releasing') && hasPendingChangesDate) {
      // If on the "Releasing" step and there are unsaved changes, show the modal
      // We can expand upon this, we should use it on any step with unsaved changes
      setNextStep(index);
      setDialogOpen(true);
    } else {
      // Otherwise, proceed with the navigation
      setActiveStep(index);
      history.push(targetStep.url ?? '');
    }
  };

  const handleDialogConfirm = () => {
    if (nextStep !== null) {
      const targetStep = releaseStepLinks[nextStep];
      setActiveStep(nextStep);
      history.push(targetStep.url ?? '');
      setNextStep(null); // Reset the next step
    }
    setDialogOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setNextStep(null); // Reset the next step
  };

  // end modal functions //

  const useStepIconStyles = makeStyles<Theme, StepIconProps>(theme => ({
    stepIconRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 28,
      height: 28,
      borderRadius: '50%',
      zIndex: 99,
      border: props =>
        props.isFirst || props.completed
          ? `2px solid ${theme.palette.success.main}`
          : props.active
          ? `2px solid ${theme.palette.primary.main}`
          : `2px solid ${theme.palette.text.disabled}`,
      backgroundColor: props =>
        props.isFirst || props.completed
          ? theme.palette.success.main
          : props.active
          ? theme.palette.background.paper
          : theme.palette.background.paper,
      color: props =>
        props.isFirst || props.completed ? theme.palette.common.white : props.active ? theme.palette.primary.main : theme.palette.text.disabled
    },
    icon: {
      fontSize: 16
    }
  }));

  const StepIconComponent: FC<StepIconProps> = ({ icon, active, completed, isFirst }) => {
    const classes = useStepIconStyles({ active, completed, isFirst, icon });
    return <div className={classes.stepIconRoot}>{React.cloneElement(icon, { className: classes.icon })}</div>;
  };

  const useStyles = makeStyles<Theme, { titleFontSize: string; titleColor: string; titleFontWeight: number }>(theme => ({
    stepper: {
      alignItems: 'flex-start',
      padding: '0 0 8px 0',
      [theme.breakpoints.down('sm')]: {
        overflowX: 'auto',
        '& .MuiStepper-alternativeLabel': {
          padding: '0'
        }
      }
    },
    stepLabelRoot: {
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        whiteSpace: 'nowrap'
      }
    },
    stepLabelIconContainer: {
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 28,
        margin: '0 4px'
      }
    },
    stepLabelLabelContainer: {
      position: 'absolute',
      width: 'auto',
      transform: 'translateY(125%)',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
        '@media (orientation: landscape)': {
          display: 'block',
          position: 'relative',
          transform: 'none',
          textAlign: 'center'
        }
      }
    },
    title: {
      fontSize: props => props.titleFontSize,
      color: props => props.titleColor,
      fontWeight: props => props.titleFontWeight
    }
  }));

  const ColorlibConnector = withStyles((theme: Theme) => ({
    active: {
      '& $line': {
        backgroundColor: theme.palette.primary.main
      }
    },
    completed: {
      '& $line': {
        backgroundColor: theme.palette.success.main
      }
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#d0e8f8',
      borderRadius: 1,
      position: 'relative',
      top: 11,
      left: '-8px',
      width: 'calc(100% + 16px)',
      [theme.breakpoints.down('sm')]: {
        top: 0,
        left: '-12px',
        width: 'calc(100% + 16px)',
        '@media (min-width: 820px) and (max-width: 1024px) and (orientation: portrait)': {
          top: 0,
          left: '-50px',
          width: 'calc(100% + 16px)'
        },
        '@media (orientation: landscape)': {
          top: 0,
          left: '-50px',
          width: 'calc(100% + 16px)'
        }
      }
    }
  }))(StepConnector);

  const classes = useStyles({ titleFontSize, titleColor, titleFontWeight });

  const releaseStepLinks = useMemo(
    () => [
      {
        name: 'Start',
        url: ``
      },
      {
        name: 'General Info',
        url: `/employees/software-releases/${actualReleaseId}/general-information`
      },
      {
        name: 'Release Details',
        url: `/employees/software-releases/${actualReleaseId}/release-details`
      },
      {
        name: 'Waiting for Release',
        url: `/employees/software-releases/${actualReleaseId}/waiting-for-release`
      },
      {
        name: 'Releasing',
        url: `/employees/software-releases/${actualReleaseId}/releasing`
      },
      {
        name: 'Verifying',
        url: `/employees/software-releases/${actualReleaseId}/verifying`
      },
      {
        name: 'Done',
        url: `/employees/software-releases/${actualReleaseId}/done`
      }
    ],
    [actualReleaseId]
  );

  const stepsToRender = steps.map((step, index) => ({ ...step, originalIndex: index })).filter(step => !isSmallScreen || step.stepId !== 'start');
  const stepperProps = isSmallScreen
    ? {
        alternativeLabel: true
      }
    : {
        className: classes.stepper
      };

  return (
    <>
      {hasTitle && (
        <Box mb={1}>
          <Typography className={classes.title}>{title}</Typography>
        </Box>
      )}
      <ConfirmationModal
        open={dialogOpen}
        onConfirm={handleDialogConfirm}
        onCancel={handleDialogClose}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      <Box className={classes.stepper}>
        <Stepper activeStep={activeStep} connector={<ColorlibConnector />} {...stepperProps}>
          {stepsToRender.map(step => (
            <Step
              key={step.stepId}
              onClick={() => {
                if (step.url) {
                  handleStepperClick(step.originalIndex);
                }
              }}
            >
              <StepLabel
                classes={{
                  root: classes.stepLabelRoot,
                  iconContainer: classes.stepLabelIconContainer,
                  labelContainer: classes.stepLabelLabelContainer
                }}
                StepIconComponent={() => (
                  <StepIconComponent
                    icon={React.createElement(step.icon)}
                    active={activeStep === step.originalIndex}
                    completed={activeStep > step.originalIndex}
                    isFirst={step.originalIndex === 0}
                  />
                )}
              >
                {isSmallScreen ? step.mobileTitle : step.title}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </>
  );
};
