import React, { FC, useState, useEffect } from 'react';
import { reduce } from 'lodash';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { string, boolean, number, object, mixed } from 'yup';
import { deepEqual } from 'fast-equals';
import { Add, Save } from '@material-ui/icons';
import { useMedia } from 'react-use';
// Components
import { Toast } from '@shared/components/toast';
import {
  Box,
  Card,
  Chip,
  Fade,
  CardActions,
  Button,
  CardContent,
  CardHeader,
  TextField,
  Grid,
  FormControl,
  Paper,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  FormHelperText,
  Switch,
  FormControlLabel,
  Checkbox,
  Divider
} from '@material-ui/core';
import { Modal } from '@shared/components/modals/Modal';
import { Loader } from '@shared/components/loader';
// types
import {
  IExtranetUser,
  IClientInfo,
  UserRolesValue,
  UserRolesTitle,
  IUnassignedZendeskOrgsOrUsers,
  ClientUserRole,
  ClientRolesResponse,
  IAppState,
  clientRolesLabelPayloadMap
} from '@shared/types';
// fetch
import { inviteExtranetUser, getClients, updateExtranetUser, getUnassignedZendeskUsers } from '@shared/fetch';
// helpers
import { formatShortFriendlyDateWithTime } from '@shared/helpers';
// redux
import { useSelector } from 'react-redux';

interface IClientRolesFormValues {
  'Team Member': boolean;
  'Client Admin': boolean;
  Financial: boolean;
  Collaborator: boolean;
}

const makeClientRolesPayload = (clientRoleFormValues: IClientRolesFormValues): ClientUserRole[] => {
  return reduce<IClientRolesFormValues, ClientUserRole[]>(
    clientRoleFormValues,
    (payload, value, clientRoleFormLabel) => {
      if (value) {
        payload.push(clientRolesLabelPayloadMap[clientRoleFormLabel]);
      }
      return payload;
    },
    []
  );
};

const makeInitialClientRolesFormValues = (userClientRoles: ClientRolesResponse[]) => {
  return reduce(
    userClientRoles,
    (initialValues, clientRole) => {
      switch (clientRole) {
        case 'ClientAdmin': {
          initialValues['Client Admin'] = true;
          return initialValues;
        }
        case 'Financial': {
          initialValues.Financial = true;
          return initialValues;
        }
        case 'Collaborator': {
          initialValues.Collaborator = true;
          return initialValues;
        }
        default:
          return initialValues;
      }
    },
    {
      'Team Member': false,
      'Client Admin': false,
      Financial: false,
      Collaborator: false
    }
  );
};

interface IExternalUserProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  currentUser: IExtranetUser | null;
  isRequestsPage?: boolean;
  zendeskAssignment?: any;
  clientView: boolean;
}

const ExternalEditUserSchema = object().shape({
  firstName: string().max(255, 'Max 255 characters').required('Required'),
  lastName: string().max(255, 'Max 255 characters').required('Required'),
  email: string().required('Required').max(255, 'Max 255 characters').email('Email address invalid'),
  clientName: mixed().when('role', {
    is: (val: string) => {
      return val === UserRolesValue.EXTERNAL;
    },
    then: string().required('Required'),
    otherwise: string().notRequired()
  }),
  status: boolean(),
  role: string().required('Required'),
  clientRoles: object({
    'Team Member': boolean(),
    'Client Admin': boolean(),
    Financial: boolean(),
    Collaborator: boolean()
  }),
  zendeskUserId: number().nullable()
});

const ExternalUserSchema = object().shape({
  firstName: string().max(255, 'Max 255 characters').required('Required'),
  lastName: string().max(255, 'Max 255 characters').required('Required'),
  email: string()
    .required('Required')
    .max(255, 'Max 255 characters')
    .email('Email address invalid')
    .matches(/^((?!mercuryworks).)*$/, {
      message:
        'MercuryWorks employees cannot be added through this screen. To add a MercuryWorks employee, please go to the Employees page under the Admin menu.'
    })
    .matches(/^((?!mercurynewmedia).)*$/, {
      message:
        'MercuryWorks employees cannot be added through this screen. To add a MercuryWorks employee, please go to the Employees page under the Admin menu.'
    }),
  clientName: string().required('Required'),
  status: boolean(),
  sendEmail: boolean(),
  role: string().required('Required'),
  zendeskUserId: number().nullable(),
  welcomeEmail: mixed().when('sendEmail', {
    is: (val: boolean) => val,
    then: string().required('Required'),
    otherwise: string().notRequired()
  })
});

export const ExternalUser: FC<IExternalUserProps> = ({ open, onClose, onSave, currentUser, isRequestsPage, zendeskAssignment, clientView }) => {
  const classes = useStyles();
  const [isError, showError] = useState<boolean>(false);
  const [isSuccess, showSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [clients, setClients] = useState<IClientInfo[]>([]);
  const [loadingZendeskUsers, setLoadingZendeskUsers] = useState<boolean>(false);
  const [zendeskUsers, setZendeskUsers] = useState<IUnassignedZendeskOrgsOrUsers[]>([]);

  const isDesktop = useMedia('(min-width: 960px)');

  const editing = currentUser && !isRequestsPage;
  const newUser = !currentUser;
  const isClientEditView = editing && clientView

  // redux
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);

  const fetchClients = async () => {
    try {
      const clientsResponse = await getClients(true);
      setClients(clientsResponse);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchUnassignedZendeskUsers = async () => {
    try {
      setLoadingZendeskUsers(true);
      const zendeskUsers = await getUnassignedZendeskUsers();
      const allZendeskUsers = [zendeskAssignment, ...zendeskUsers];
      setZendeskUsers(allZendeskUsers);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingZendeskUsers(false);
    }
  };

  useEffect(() => {
    if(clientView)
      return;

    fetchUnassignedZendeskUsers();
  }, [currentUser, zendeskAssignment]);

  useEffect(() => {
      fetchClients();
  }, []);

  return (
    <>
      <Formik
        enableReinitialize={true} 
        validateOnMount   
        initialValues={{
          role:
            currentUser && !currentUser.role && currentUser.userType === 'Member'
              ? UserRolesValue.USER
              : currentUser && currentUser.role
              ? currentUser.role
              : UserRolesValue.EXTERNAL,
          clientRoles: makeInitialClientRolesFormValues(currentUser?.clientRoles ? currentUser.clientRoles : []),
          firstName: currentUser && currentUser.firstName ? currentUser.firstName : '',
          lastName: currentUser && currentUser.lastName ? currentUser.lastName : '',
          email: currentUser && currentUser.email ? currentUser.email : '',
          clientName: (currentUser?.clientName) || (clientView && selectedClient?.name) || '',
          status: currentUser ? currentUser.isEnabled : true,
          zendeskUserId: zendeskAssignment?.value ?? '',
          sendEmail: true,
          welcomeEmail:
            'Hi,\n\nThis email is to inform you that you have been granted access to the My MercuryWorks portal. Please follow the link in this email to finish the process to create your account. Once your account is created, you can visit the My MercuryWorks portal anytime at https://my.mwks.io/.\n\nSincerely,\nThe MercuryWorks Team'
        }}
        validationSchema={currentUser && !isRequestsPage ? ExternalEditUserSchema : ExternalUserSchema}
        onSubmit={async (values, actions) => {
          const selectedClient = clients.find(client => client.name === values.clientName);
          try {
            var currentUserHasAzureId = currentUser?.id;
            // on the requests page, send an update to the update with all of the add fields
            if (currentUser && currentUserHasAzureId && isRequestsPage) {
              await updateExtranetUser(currentUser.id, {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                clientId: selectedClient ? selectedClient.clientId : 274, // All clientId
                isActive: values.status,
                role: values.role,
                zendeskUserId: values.zendeskUserId,
                sendEmail: values.sendEmail,
                emailMessage: values.welcomeEmail,
                clientRoles: makeClientRolesPayload(values.clientRoles)
              });
            }
            if (currentUser && !currentUserHasAzureId && isRequestsPage) {
              await inviteExtranetUser({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                clientId: selectedClient ? selectedClient.clientId : 274, // All clientId
                isActive: values.status,
                sendEmail: values.sendEmail,
                emailMessage: values.welcomeEmail,
                zendeskUserId: values.zendeskUserId
              });
            }
            // the editing an active user
            if (currentUser && !isRequestsPage) {
              await updateExtranetUser(currentUser.id, {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                clientId: selectedClient ? selectedClient.clientId : 274, // All clientId
                isActive: values.status,
                role: values.status ? values.role : null,
                zendeskUserId: values.zendeskUserId ?? null,
                clientRoles: makeClientRolesPayload(values.clientRoles)
              });
              setSuccessMessage('User Updated!');
            }
            // inviting a brand new user
            if (selectedClient && !currentUser) {
              await inviteExtranetUser({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                clientId: selectedClient.clientId,
                isActive: values.status,
                sendEmail: values.sendEmail,
                emailMessage: values.welcomeEmail,
                zendeskUserId: values.zendeskUserId
              });
            }
            showSuccess(true);
            onClose();
            onSave();
            actions.resetForm();
          } catch (error) {
            console.error(error);
            if (error && error?.response && error?.response.data && error?.response.data.Detail) {
              setErrorMessage(error?.response.data.Detail);
            }
            showError(true);
          }
        }}
      >
        {({ resetForm, isSubmitting, values, initialValues, setFieldValue, errors, touched, handleSubmit, dirty, isValid, handleBlur }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                if (!deepEqual(initialValues, values)) {
                  const result = window.confirm('You have unsaved changes, are you sure you want to close the modal?');
                  if (result) {
                    resetForm();
                    onClose();
                  } else {
                    return;
                  }
                } else {
                  onClose();
                  resetForm();
                }
              }}
              maxWidth='md'
            >
              {isSubmitting && <Loader type='overlay' position='centered' />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit}>
                  <Typography component='h3' variant='h4'>
                    {currentUser && !isRequestsPage ? 'Edit User' : 'Add New External User'}
                  </Typography>
                  <div className={classes.content}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Card elevation={0} variant='outlined' className={classes.marginBottom}>
                          <CardHeader
                            title='User Information'
                            className={classes.primaryHeader}
                            titleTypographyProps={{ component: 'h4', className: 'fontWeight-normal' }}
                          />
                          <CardContent>
                            {currentUser && (
                              <Grid container className={classes.lastLogin}>
                                {currentUser.lastLogin && (
                                  <Typography component='span'>
                                    <Typography className={classes.bold} component='span'>
                                      Last Login
                                    </Typography>{' '}
                                    on {formatShortFriendlyDateWithTime(new Date(currentUser.lastLogin))}
                                  </Typography>
                                )}
                                {isDesktop && currentUser.updatedBy && <Divider orientation='vertical' flexItem />}
                                {currentUser.updatedBy && currentUser.updatedDate && (
                                  <Typography component='span'>
                                    <Typography className={classes.bold} component='span'>
                                      Last Updated
                                    </Typography>{' '}
                                    by {currentUser.updatedBy} on{' '}
                                    {currentUser.updatedDate ? formatShortFriendlyDateWithTime(new Date(currentUser.updatedDate)) : ''}
                                  </Typography>
                                )}
                              </Grid>
                            )}
                            <Paper elevation={0} square>
                              <div className={classes.column}>
                                <Grid container spacing={1}>
                                  <Grid item xs={12} sm={isClientEditView ? 12 : 6}>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={values.status}
                                          id='active-inactive'
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue('status', e.target.checked);
                                          }}
                                        />
                                      }
                                      label='Active'
                                    />
                                  </Grid>
                                  {!isClientEditView && <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth variant='outlined' required>
                                      <InputLabel id='is-active-label' className={classes.outlinedLabel}>
                                        Role
                                      </InputLabel>
                                      <Select
                                        name='role'
                                        labelId='role'
                                        id='role'
                                        value={values.role}
                                        disabled={values.role.includes(UserRolesValue.EXTERNAL) }
                                        onBlur={handleBlur}
                                        onChange={({ target: { value } }) => setFieldValue('role', value)}
                                        error={Boolean(touched.role && errors.role)}
                                      >
                                        {!values.role.includes('Mwks') && <MenuItem value={UserRolesValue.EXTERNAL}>External User</MenuItem>}
                                        <MenuItem value={UserRolesValue.ADMIN}>{UserRolesTitle.ADMIN}</MenuItem>
                                        <MenuItem value={UserRolesValue.USER}>{UserRolesTitle.USER}</MenuItem>
                                      </Select>
                                      {touched.role && errors.role && <FormHelperText>{errors.role}</FormHelperText>}
                                    </FormControl>
                                  </Grid>}
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      required
                                      variant='outlined'
                                      label='First Name'
                                      name='firstName'
                                      value={values.firstName}
                                      onBlur={handleBlur}
                                      disabled={(currentUser && values.role.includes('Mwks')) ? true : false}
                                      onChange={e => setFieldValue('firstName', e.target.value)}
                                      error={Boolean(touched.firstName && errors.firstName)}
                                      helperText={touched.firstName && errors.firstName}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      required
                                      variant='outlined'
                                      label='Last Name'
                                      name='lastName'
                                      value={values.lastName}
                                      onBlur={handleBlur}
                                      disabled={(currentUser && values.role.includes('Mwks')) ? true : false}
                                      onChange={e => setFieldValue('lastName', e.target.value)}
                                      error={Boolean(touched.lastName && errors.lastName)}
                                      helperText={touched.lastName && errors.lastName}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      required
                                      variant='outlined'
                                      label='Email'
                                      name='email'
                                      value={values.email}
                                      onBlur={handleBlur}
                                      onChange={e => {
                                        setFieldValue('email', e.target.value);
                                      }}
                                      disabled={(currentUser && values.role.includes('Mwks')) ? true : false}
                                      error={Boolean(touched.email && errors.email)}
                                      helperText={touched.email && errors.email}
                                    />
                                  </Grid>
                                  {!isClientEditView && <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth variant='outlined' required={initialValues.role === UserRolesValue.EXTERNAL}>
                                      <InputLabel id='is-active-label' className={classes.outlinedLabel}>
                                        Client
                                      </InputLabel>
                                      <Select
                                        name='clientName'
                                        labelId='clientName'
                                        id='clientName'
                                        value={values.clientName}
                                        onBlur={handleBlur}
                                        disabled={clientView || (currentUser ? values.role.includes('Mwks') : false)}
                                        onChange={({ target: { value } }) => setFieldValue('clientName', value)}
                                        error={Boolean(touched.clientName && errors.clientName)}
                                      >
                                        {values.role.includes('Mwks') && <MenuItem value={'All'}>All</MenuItem>}
                                        {clients.map(({ clientId, name, isDisabled }) => {
                                          return isDisabled ? (
                                            <MenuItem key={clientId} value={name} disabled>
                                              {name} (not configured)
                                            </MenuItem>
                                          ) : (
                                            <MenuItem key={clientId} value={name}>
                                              {name}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                      {touched.clientName && errors.clientName && <FormHelperText error>{errors.clientName}</FormHelperText>}
                                    </FormControl>
                                  </Grid>}
                                  {!clientView && <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth variant='outlined'>
                                      <InputLabel id='zendesk-users-active-label' className={classes.outlinedLabel}>
                                        Zendesk User
                                      </InputLabel>
                                      <Select
                                        disabled={loadingZendeskUsers}
                                        labelId='zendesk-users-active-label'
                                        value={values?.zendeskUserId}
                                        onChange={({ target: { value } }) => {
                                          setFieldValue('zendeskUserId', value);
                                        }}
                                        onBlur={handleBlur}
                                        error={Boolean(touched.zendeskUserId && errors.zendeskUserId)}
                                      >
                                        {zendeskUsers?.map(user => (
                                          <MenuItem key={user?.value} value={user?.value}>
                                            {user?.description}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      {touched.zendeskUserId && errors.zendeskUserId && <FormHelperText>{errors.zendeskUserId}</FormHelperText>}
                                    </FormControl>
                                  </Grid>}
                                  {currentUser?.role && currentUser.role === 'External' && <Grid item xs={12} sm={6}>
                                    <Typography className={`MuiFormLabel-root ${classes.formLabel}`}>Client Roles</Typography>
                                    <Box className={classes.chipContainer}>
                                      {["Team Member", "Financial", "Collaborator", "Client Admin"].map(clientRole => {
                                        const currClientRoles = values.clientRoles
                                        if (clientRole === "Team Member") {
                                          return (
                                            <Chip
                                              disabled
                                              key={clientRole}
                                              className={classes.chip}
                                              label='Team Member'
                                            />
                                          )
                                        }
                                        return (
                                          <Chip
                                            key={clientRole}
                                            className={classes.chip}
                                            label={clientRole}
                                            color={currClientRoles[clientRole] ? "primary" : "default"}
                                            variant={currClientRoles[clientRole] ? "default" : "outlined"}
                                            onClick={() => {
                                              setFieldValue('clientRoles', {
                                                ...currClientRoles,
                                                [clientRole]: !currClientRoles[clientRole]
                                              })
                                            }}
                                          />
                                        )
                                      })}
                                    </Box>
                                  </Grid>}
                                </Grid>
                              </div>
                            </Paper>
                          </CardContent>
                        </Card>
                      </Grid>
                      {(!currentUser || isRequestsPage) && (
                        <Grid item xs={12}>
                          <Card elevation={0} variant='outlined' className={classes.marginBottom}>
                            <CardHeader
                              title='Welcome Email'
                              className={classes.primaryHeader}
                              titleTypographyProps={{ component: 'h4', className: 'fontWeight-normal' }}
                            />
                            <CardContent>
                              <Paper elevation={0} square>
                                <div className={classes.column}>
                                  <FormControlLabel
                                    classes={{
                                      root: classes.checkboxRoot
                                    }}
                                    control={
                                      <Checkbox
                                        checked={values.sendEmail}
                                        onChange={e => setFieldValue('sendEmail', e.target.checked)}
                                        name='sendEmail'
                                        color='secondary'
                                      />
                                    }
                                    label='Send Email?'
                                  />
                                  <TextField
                                    fullWidth
                                    variant='outlined'
                                    label='Welcome Email'
                                    name='welcomeEmail'
                                    multiline={true}
                                    rows={3}
                                    inputProps={{
                                      style: {
                                        resize: 'vertical',
                                        minHeight: '160px'
                                      }
                                    }}
                                    value={values.welcomeEmail}
                                    onBlur={handleBlur}
                                    onChange={e => setFieldValue('welcomeEmail', e.target.value)}
                                    error={Boolean(touched.welcomeEmail && errors.welcomeEmail)}
                                    helperText={touched.welcomeEmail && errors.welcomeEmail}
                                  />
                                </div>
                              </Paper>
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                  <CardActions>
                    <Button
                      // ignore dirty flag  
                      disabled={(!dirty && (editing || newUser || !clientView)) || isSubmitting || !isValid}
                      type='submit'
                      startIcon={
                        isDesktop && currentUser && !isRequestsPage ? <Save /> : (!currentUser || isRequestsPage) && isDesktop ? <Add /> : undefined
                      }
                      variant='contained'
                      color='secondary'
                    >
                      {editing ? 'Save User' : 'Add User'}
                    </Button>
                    <Button
                      type='button'
                      variant='contained'
                      color='default'
                      onClick={() => {
                        if (!deepEqual(initialValues, values)) {
                          const result = window.confirm('You have unsaved changes, are you sure you want to close the modal?');
                          if (result) {
                            resetForm();
                            onClose();
                          } else {
                            return;
                          }
                        } else {
                          onClose();
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </CardActions>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
      <Toast id='user-success' message={successMessage || `User Added!`} open={isSuccess} onClose={() => showSuccess(false)} variant='success' />
      <Toast
        id='user-error'
        autoHideDuration={6000}
        message={
          errorMessage ||
          'We were unable to create the user at this time. Please try again later. Please contact MercuryWorks support if this issue continues.'
        }
        open={isError}
        onClose={() => showError(false)}
        variant='error'
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formLabel: {
    marginBottom: '10px'
  },
  chipContainer: {
    display: 'flex',
    width: '100%'
  },
  chip: {
    marginRight: '6px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  pathInput: {
    display: 'flex',
    alignItems: 'center'
  },
  addButton: {
    marginTop: theme.spacing(1)
  },
  deleteButton: {
    padding: 0,
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main
  },
  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1)
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(1)
    }
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  buttonWrapper: {
    marginLeft: 'auto'
  },
  checkboxRoot: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(0.5),
    width: '9rem'
  },
  content: {
    marginTop: theme.spacing(1)
  },
  welcomeEmailError: {
    '&& > div': {
      borderColor: theme.palette.error.main
    }
  },
  lastLogin: {
    fontStyle: 'italic',
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(-0.5),
    color: theme.palette.grey[500],

    '&& > span:first-child': {
      marginRight: '.5rem'
    },
    '&& > span:last-child': {
      '@media (max-width: 804px)': {
        marginTop: '1rem'
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: '.5rem'
      }
    }
  },
  bold: {
    fontWeight: 'bold'
  }
}));
