import React, { FC, useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withFormik, FormikProps } from 'formik';
import { object, string, number, array } from 'yup';
// Redux
import { useSelector } from 'react-redux';
// Components
import {
  Toolbar,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Select,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Divider,
  FormHelperText,
  Tooltip,
  IconButton,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Add, Save, Close, Delete, Edit } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LoaderOverlay, Loader } from '@shared/components/loader';
import { DatePicker } from '@shared/components/inputs';
import { BudgetAdjustment } from '../modals';
// Types
import { IAppState, IClientProject, IUserProps, IClientProjectLaborRate, IRevenueCategory } from '@shared/types';
// helpers
import { formatMoney, stripMoneyFormat } from '@shared/helpers';
import { userAccess } from '@shared/constants';
// Fetch
import { getClientProjectLaborRates } from '@shared/fetch';
import { PageTitle } from '@shared/components/layout';
import { RateContent } from '../cards/RateContent';

interface IExpectedBillingItem {
  percentage: number | string;
  clientProjectExpectedBillingId: number | null;
  clientProjectId: number | null;
  date: string;
  isDeleted: boolean;
}

export interface IClientProjectFormValues {
  clientProjectId?: null | number;
  name: string;
  clientId?: number;
  clientName: string;
  projectStatus?: null | number;
  projectTypeId?: null | number;
  hashtag: string;
  codeBaseId?: null | number;
  revenueCategoryId?: null | number;
  description: string;
  billingTypeId: number | undefined;
  budgetedAmount?: null | number | string;
  scopedHours?: null | number;
  actualSpend?: null | number;
  blendedRate: number;
  fixedPrice: boolean;
  expectedBilling: IExpectedBillingItem[];
  hasBudgetOrScopedHours: boolean;
  comment: string;
  shouldShowInClientPortal: boolean;
  contractReviewDate?: string;
}

interface IClientProjectFormProps {
  initialValues: IClientProjectFormValues | {};
  onCancel: () => void;
  onSave: (values: IClientProject, callBack: (error?: Error) => void) => void;
  revenueCategories: IRevenueCategory[];
}

const ClientProjectForm: FC<IClientProjectFormProps & FormikProps<IClientProjectFormValues>> = ({
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  initialValues,
  errors,
  touched,
  submitForm,
  onCancel,
  revenueCategories,
  isSubmitting
}) => {
  const classes = useStyles();

  // redux
  const { clientProjectStatuses, clientProjectTypes } = useSelector((state: IAppState) => state.clients);
  const { billingTypes } = useSelector((state: IAppState) => state.admin);
  const user: IUserProps = useSelector((state: IAppState) => state.user);

  useEffect(() => {
    if (values.budgetedAmount && values.scopedHours) {
      setFieldValue('blendedRate', Number(stripMoneyFormat(values.budgetedAmount)) / Number(values.scopedHours));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.budgetedAmount, values.scopedHours]);

  const revenueCategoryValue = useMemo(() => {
    const find = revenueCategories.find(x => x.revenueCategoryId === values.revenueCategoryId);
    if (find) {
      return { label: find.name, value: find.revenueCategoryId };
    }
    return null;
  }, [revenueCategories, values.revenueCategoryId]);

  // client project rates
  const [laborRates, setLaborRates] = useState<IClientProjectLaborRate[]>([]);
  const [isLoadingLaborRates, setIsLoadingLaborRates] = useState<boolean>(false);
  const [laborRatesError, setLaborRatesError] = useState<boolean>(false);
  const loadLaborRates = async () => {
    if (values && values.clientProjectId) {
      try {
        setLaborRatesError(false);
        setIsLoadingLaborRates(true);
        const laborRatesResponse = await getClientProjectLaborRates(values.clientProjectId);
        setLaborRates(laborRatesResponse);
        setIsLoadingLaborRates(false);
      } catch (error) {
        console.error(error);
        setLaborRatesError(true);
        setIsLoadingLaborRates(false);
      }
    }
  };

  useEffect(() => {
    loadLaborRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.clientProjectId]);

  const nonDeleted = values.expectedBilling.filter(item => !item.isDeleted);
  const percentages = nonDeleted.map(item => Number(item.percentage));
  const total = percentages && percentages.length && percentages.reduce((accumulator, currentValue) => accumulator + currentValue);
  const [totalError, setTotalError] = useState<string | null>(null);
  const [isShowingBudgetAdjustment, showBudgetAdjustment] = useState<boolean>(false);

  const validatePercentage = () => {
    if (total > 0 && total < 100) {
      setTotalError('Total must equal 100%');
    } else if (total > 100) {
      setTotalError('Total cannot exceed 100%');
    } else if (total === 100) {
      setTotalError(null);
    }
  };

  useEffect(() => {
    validatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.expectedBilling]);

  const handleDelete = (index: number) => {
    let updated = [...(values && values.expectedBilling)];
    updated[index].isDeleted = true;
    setFieldValue('expectedBilling', updated);
  };

  const getAmount = (item: IExpectedBillingItem) => {
    if (values.budgetedAmount) {
      const budget = values.budgetedAmount.toString().replace('$', '');
      const budgetNumber = parseFloat(budget.replace(/,/g, ''));
      return Number(item.percentage) * 0.01 * budgetNumber;
    }
  };

  const addBillingItem = () => {
    const item = {
      date: new Date().toISOString(),
      percentage: 100 - total >= 0 ? 100 - total : 0,
      clientProjectId: values.clientProjectId,
      isDeleted: false
    };
    setFieldValue('expectedBilling', [...values.expectedBilling, item]);
  };

  const hasBudgetOrScopedHours = initialValues.hasBudgetOrScopedHours;
  return isLoadingLaborRates ? (
    <Loader position='centered' type='overlay' size='large' />
  ) : (
    <>
      <Toolbar disableGutters className={classes.toolbar}>
        <PageTitle title={values.clientProjectId ? values.name : 'Add Client Project'} />
        <div className={classes.buttonWrapper}>
          <Button size='small' color='secondary' startIcon={values.clientProjectId ? <Save /> : <Add />} onClick={() => submitForm()}>
            {values.clientProjectId ? 'Save Project' : 'Add Project'}
          </Button>
          <Button size='small' color='inherit' startIcon={<Close />} onClick={() => onCancel()}>
            Cancel
          </Button>
        </div>
      </Toolbar>
      <Divider className={classes.divider} />
      <div>
        {values.clientName && (
          <Typography className={classes.clientName} component='h2'>
            {values.clientName}
          </Typography>
        )}
        <Grid container spacing={1}>
          <Grid item sm={12} md={6}>
            <Card elevation={0} variant='outlined' className={classes.marginBottom}>
              <CardHeader
                title='Project Information'
                className={classes.primaryHeader}
                titleTypographyProps={{ component: values.clientName ? 'h3' : 'h2', className: 'fontWeight-normal' }}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Paper elevation={0} square>
                      <div className={classes.column}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          label='Project Name'
                          name='name'
                          required
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          autoFocus
                        />
                        <TextField
                          fullWidth
                          variant='outlined'
                          label='Hashtag'
                          name='hashtag'
                          required
                          value={values.hashtag}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.hashtag && errors.hashtag)}
                          helperText={touched.hashtag && errors.hashtag}
                        />
                        <FormControl fullWidth variant='outlined' required>
                          <InputLabel id='project-status-label' className={classes.outlinedLabel}>
                            Status
                          </InputLabel>
                          <Select
                            name='projectStatus'
                            labelId='project-status-label'
                            id='project-status'
                            value={values.projectStatus}
                            onChange={({ target: { value } }) => setFieldValue('projectStatus', value as number)}
                            onBlur={handleBlur}
                            error={Boolean(touched.projectStatus && errors.projectStatus)}
                          >
                            {clientProjectStatuses.map(x => (
                              <MenuItem key={`${x.text}-${x.value}`} value={x.value}>
                                {x.description}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.projectStatus && errors.projectStatus && <FormHelperText>{errors.projectStatus}</FormHelperText>}
                        </FormControl>
                        <FormControl fullWidth variant='outlined' required>
                          <InputLabel id='project-type-label' className={classes.outlinedLabel}>
                            Project Type
                          </InputLabel>
                          <Select
                            name='projectTypeId'
                            labelId='project-type-label'
                            id='project-type'
                            value={values.projectTypeId}
                            onChange={({ target: { value } }) => setFieldValue('projectTypeId', value as number)}
                            onBlur={handleBlur}
                            error={Boolean(touched.projectTypeId && errors.projectTypeId)}
                          >
                            {clientProjectTypes.map(x => (
                              <MenuItem key={`${x.projectTypeId}-${x.name}`} value={x.projectTypeId}>
                                {x.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.projectTypeId && errors.projectTypeId && <FormHelperText>{errors.projectTypeId}</FormHelperText>}
                        </FormControl>
                        <Autocomplete
                          id='revenue-search-autocomplete'
                          options={revenueCategories.map(category => ({ label: category.name, value: category.revenueCategoryId }))}
                          getOptionLabel={option => option && option.label}
                          value={revenueCategoryValue}
                          onChange={(e: any, value: any) => setFieldValue('revenueCategoryId', value ? value.value : null)}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label='Revenue Category'
                              name='revenueCategoryId'
                              variant='outlined'
                              error={Boolean(touched.revenueCategoryId && errors.revenueCategoryId)}
                              helperText={touched.revenueCategoryId && errors.revenueCategoryId}
                            />
                          )}
                        />
                        <DatePicker
                          id='date-picker'
                          label='Contract Review Date'
                          name='date'
                          inputVariant='outlined'
                          value={values.contractReviewDate ? new Date(values.contractReviewDate) : null}
                          onChange={date => setFieldValue('contractReviewDate', date?.toISOString())}
                          onBlur={handleBlur}
                          disableMargin
                        />
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper elevation={0} square>
                      <div className={classes.column}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          label='Description'
                          name='description'
                          rows={20}
                          value={values.description || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          multiline
                          error={Boolean(touched.description && errors.description)}
                          helperText={touched.description && errors.description}
                        />
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} md={6}>
            <Card elevation={0} variant='outlined' className={classes.marginBottom}>
              <CardHeader
                title='Budget Information'
                className={classes.primaryHeaderBudget}
                titleTypographyProps={{ component: values.clientName ? 'h3' : 'h2', className: 'fontWeight-normal' }}
              />
              {hasBudgetOrScopedHours && (
                <Button
                  className={classes.budgetAdjustmentButton}
                  size='small'
                  color='primary'
                  startIcon={<Edit />}
                  onClick={() => showBudgetAdjustment(true)}
                >
                  Budget Adjustment
                </Button>
              )}
              <CardContent>
                <Grid container direction='row' justify='flex-start' spacing={1} className={classes.budgetInformationGrid}>
                  <Grid item xs={12} md={6}>
                    <Paper elevation={0} square>
                      <FormControl fullWidth variant='outlined' required>
                        <InputLabel id='billing-type-label' className={classes.outlinedLabel}>
                          Default Billing Type
                        </InputLabel>
                        <Select
                          name='billingTypeId'
                          labelId='billing-type-label'
                          id='billing-type'
                          value={values.billingTypeId}
                          onChange={({ target: { value } }) => setFieldValue('billingTypeId', value as number)}
                          onBlur={handleBlur}
                          error={Boolean(touched.billingTypeId && errors.billingTypeId)}
                        >
                          {billingTypes.map(x => (
                            <MenuItem key={`${x.text}-${x.value}`} value={x.value}>
                              {x.description}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.billingTypeId && errors.billingTypeId && <FormHelperText>{errors.billingTypeId}</FormHelperText>}
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper elevation={0} square>
                      <Grid container direction='row' justify='space-evenly'>
                        <Grid item className={classes.fixedPrice}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.shouldShowInClientPortal}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name='shouldShowInClientPortal'
                                color='secondary'
                              />
                            }
                            label='Show in Client Portal'
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper elevation={0} square>
                      <TextField
                        fullWidth
                        variant='outlined'
                        label='Budgeted Amount'
                        name='budgetedAmount'
                        disabled={hasBudgetOrScopedHours ? true : false}
                        value={values.budgetedAmount || ''}
                        onFocus={({ target }) => target.select()}
                        onChange={handleChange}
                        onBlur={e => {
                          setFieldValue('budgetedAmount', formatMoney(e.target.value, 0));
                          handleBlur(e);
                        }}
                        error={Boolean(touched.budgetedAmount && errors.budgetedAmount)}
                        helperText={touched.budgetedAmount && errors.budgetedAmount}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper elevation={0} square>
                      <TextField
                        fullWidth
                        variant='outlined'
                        label='Actual Spend'
                        name='actualSpend'
                        value={values.actualSpend ? formatMoney(values.actualSpend) || '' : ''}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper elevation={0} square>
                      <TextField
                        fullWidth
                        type='number'
                        variant='outlined'
                        label='Scoped Hours'
                        name='scopedHours'
                        disabled={hasBudgetOrScopedHours ? true : false}
                        onFocus={({ target }) => target.select()}
                        value={`${values.scopedHours}` || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.scopedHours && errors.scopedHours)}
                        helperText={touched.scopedHours && errors.scopedHours}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper elevation={0} square>
                      <TextField
                        fullWidth
                        variant='outlined'
                        label='Blended Rate'
                        name='blendedRate'
                        value={values.blendedRate ? formatMoney(values.blendedRate) : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Paper elevation={0} square>
                      <div className={classes.column}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          label='Comments'
                          name='comment'
                          disabled={hasBudgetOrScopedHours ? true : false}
                          rows={3}
                          value={values.comment || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          multiline
                          error={Boolean(touched.comment && errors.comment)}
                          helperText={touched.comment && errors.comment}
                        />
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {user.userAccess[userAccess.VIEW_ADMIN_JOB_FORM] && (
            <Grid item sm={12} md={6}>
              <Card elevation={0} variant='outlined' className={classes.marginBottom}>
                <CardHeader
                  title='Progress Billings'
                  className={classes.primaryHeader}
                  titleTypographyProps={{ component: values.clientName ? 'h3' : 'h2', className: 'fontWeight-normal' }}
                />
                <CardContent>
                  {values.expectedBilling.length > 0 &&
                    values.expectedBilling.map((item, index) =>
                      item.isDeleted ? null : (
                        <Grid key={`expectedBillingItem_${index}`} container direction='row' justify='flex-start' spacing={1}>
                          <Grid item xs={4}>
                            <Paper elevation={0} square>
                              <FormControl fullWidth variant='outlined' required>
                                <DatePicker
                                  id={`spread-date-picker_${index}`}
                                  label='Date'
                                  name='date'
                                  inputVariant='outlined'
                                  size='small'
                                  value={item.date ? new Date(item.date) : null}
                                  onChange={date => {
                                    if (date) {
                                      setFieldValue(`expectedBilling[${index}].date`, date.toISOString());
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </Paper>
                          </Grid>
                          <Grid item xs={3} classes={{ root: classes.spreadInput }}>
                            <Paper elevation={0} square classes={{ root: classes.inputPaper }}>
                              <FormControl fullWidth variant='outlined' required>
                                <TextField
                                  fullWidth
                                  variant='outlined'
                                  label='Percentage'
                                  name={`expectedBilling[$index].percentage`}
                                  size='small'
                                  value={values.expectedBilling ? values.expectedBilling[index].percentage : 0}
                                  onChange={({ target: { value } }) => {
                                    if (value) {
                                      const regex = /[0-9]|\./;
                                      if (!regex.test(value)) {
                                        return;
                                      }
                                      setFieldValue(`expectedBilling[${index}].percentage`, parseInt(value, 10));
                                    } else {
                                      setFieldValue(`expectedBilling[${index}].percentage`, '');
                                    }
                                  }}
                                  onBlur={validatePercentage}
                                  error={Boolean(errors.expectedBilling)}
                                />
                              </FormControl>
                            </Paper>
                          </Grid>
                          <Grid item xs={4} classes={{ root: classes.spreadInput }}>
                            <Paper elevation={0} square classes={{ root: classes.inputPaper }}>
                              <FormControl fullWidth variant='outlined' required>
                                <TextField
                                  fullWidth
                                  variant='outlined'
                                  label='Amount'
                                  name='amount'
                                  size='small'
                                  value={formatMoney(getAmount(values.expectedBilling[index])) || formatMoney(0)}
                                  InputProps={{
                                    readOnly: true
                                  }}
                                />
                              </FormControl>
                            </Paper>
                          </Grid>
                          <Grid item xs={1} classes={{ root: classes.deleteContainer }}>
                            <Paper elevation={0} square>
                              <Tooltip title='Delete Row'>
                                <IconButton
                                  size='small'
                                  aria-label='delete-rate'
                                  onClick={() => handleDelete(index)}
                                  className={classes.deleteButton}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            </Paper>
                          </Grid>
                        </Grid>
                      )
                    )}
                  {totalError && (
                    <Typography variant='body1' color='error'>
                      {totalError}
                    </Typography>
                  )}
                  <Button variant='contained' color='secondary' onClick={addBillingItem} classes={{ root: classes.addButton }}>
                    Add Expected Billing
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Card elevation={0} variant='outlined'>
              <CardHeader
                title='Rates'
                className={classes.primaryHeader}
                titleTypographyProps={{ component: values.clientName ? 'h3' : 'h2', className: 'fontWeight-normal' }}
              />
              <CardContent className={classes.noVertSpace}>
                {isLoadingLaborRates && <Loader position='centered' type='inline' size='large' />}
                {!isLoadingLaborRates && !laborRatesError && <RateContent laborRates={laborRates} />}
                {!isLoadingLaborRates && laborRatesError && <Alert severity='error'>Error loading rates!</Alert>}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <LoaderOverlay open={isSubmitting} />
      <BudgetAdjustment
        clientProjectId={values.clientProjectId}
        blendedRate={values.blendedRate}
        onClose={({ budgetedAmount, scopedHours, comment }: Pick<IClientProjectFormValues, 'budgetedAmount' | 'scopedHours' | 'comment'>) => {
          showBudgetAdjustment(false);
          setFieldValue('budgetedAmount', formatMoney(budgetedAmount, 0));
          setFieldValue('scopedHours', scopedHours);
          setFieldValue('comment', comment);
        }}
        open={isShowingBudgetAdjustment}
      />
    </>
  );
};

const containsHashtagOrSpace = (value: string) => /[\#\s]/.test(value);

const ClientProjectSchema = object().shape({
  name: string().nullable().required('Project Name is Required.'),
  projectStatus: string().nullable().required('Status is Required.'),
  projectTypeId: number().nullable().required('Type is Required.'),
  hashtag: string()
    .nullable()
    .test('Cannot Contain a # or Space', 'Cannot Contain a # or Space', value => !containsHashtagOrSpace(value))
    .required('Hashtag is Required.'),
  billingTypeId: number().nullable().required('Billing Type is Required.'),
  expectedBilling: array()
    .of(
      object().shape({
        date: string().required('Date is Required.'),
        percentage: number().required('Percentage is Required')
      })
    )
    .nullable()
});

export default withFormik<IClientProjectFormProps, IClientProjectFormValues>({
  enableReinitialize: true,
  validateOnBlur: true,
  validateOnChange: false,
  mapPropsToValues: ({ initialValues = {} }) => {
    return {
      name: '',
      clientProjectId: null,
      clientName: '',
      projectStatus: 2,
      projectTypeId: 1,
      hashtag: '',
      codeBaseId: null,
      shouldShowInClientPortal: (initialValues as IClientProjectFormValues).shouldShowInClientPortal,
      description: '',
      billingTypeId: undefined,
      scopedHours: null,
      blendedRate: 0,
      fixedPrice: false,
      hasBudgetOrScopedHours: false,
      ...initialValues,
      budgetedAmount:
        initialValues && (initialValues as IClientProjectFormValues).budgetedAmount
          ? formatMoney((initialValues as IClientProjectFormValues).budgetedAmount, 0)
          : null,
      expectedBilling: (initialValues && (initialValues as IClientProjectFormValues).expectedBilling) || [],
      comment: initialValues && (initialValues as IClientProjectFormValues).comment ? (initialValues as IClientProjectFormValues).comment : ''
    };
  },
  validationSchema: ClientProjectSchema,
  handleSubmit: (values, { props: { onSave }, setSubmitting, resetForm }) => {
    setSubmitting(true);
    onSave(
      {
        ...values,
        budgetedAmount: stripMoneyFormat(values.budgetedAmount),
        clientProjectId: values.clientProjectId,
        comment: values.comment,
        expectedBilling: values.expectedBilling.filter(item => {
          let updated = {};
          if (item.clientProjectExpectedBillingId || (!item.clientProjectExpectedBillingId && !item.isDeleted)) {
            updated = item;

            if (item.clientProjectExpectedBillingId === null) {
              // @ts-ignore
              delete item.clientProjectExpectedBillingId;
            }
          }
          return updated;
        })
      } as IClientProject,
      (error?: Error) => {
        if (error) {
          setSubmitting(false);
        } else {
          setSubmitting(false);
          resetForm(); // allows for the dirty flag to reset
        }
        Promise.resolve();
      }
    );
  }
})(ClientProjectForm);

const useStyles = makeStyles(theme => ({
  addButton: {
    marginTop: theme.spacing(1)
  },
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1)
  },
  primaryHeaderBudget: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
    padding: '15px 16px 36px 16px',
    '@media (min-width: 380px)': {
      padding: 16
    }
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
    position: 'relative'
  },
  buttonToolbar: {
    '& > button': {
      marginRight: theme.spacing(1)
    }
  },
  delete: {
    color: theme.palette.error.main
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(1)
    }
  },
  descriptionMultiline: {
    height: theme.spacing(8),
    '& textarea': {
      height: '100%'
    }
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  budgetInformationGrid: {
    '& > div': {
      minWidth: '20%'
    }
  },
  divider: {
    marginBottom: '0'
  },
  smallCard: {
    maxWidth: '570px'
  },
  rateBox: {
    paddingRight: '1rem'
  },
  rateType: {
    color: theme.palette.grey[500],
    width: 90
  },
  noVertSpace: {
    marginBottom: '0',
    marginTop: '0',
    paddingBottom: '0',
    paddingTop: '0'
  },
  toolbar: {
    flexWrap: 'wrap',
    minHeight: 'auto',
    '@media (min-width: 960px)': {
      flexWrap: 'nowrap',
      minHeight: '56px'
    }
  },
  buttonWrapper: {
    margin: theme.spacing(1, 0),
    '@media (min-width: 960px)': {
      margin: 0,
      marginLeft: 'auto'
    }
  },
  spreadInput: {
    display: 'flex',
    alignItems: 'center'
  },
  inputPaper: {
    width: '100%',
    marginTop: theme.spacing(0.5)
  },
  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  deleteButton: {
    padding: 0,
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main
  },
  fixedPrice: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      fill: theme.palette.grey[600],
      fontSize: theme.spacing(1),
      marginLeft: -5
    }
  },
  content: {
    flexGrow: 1,
    maxHeight: 'calc(100vh - 375px)',
    overflowX: 'hidden'
  },
  budgetAdjustmentButton: {
    position: 'absolute',
    left: 16,
    top: 38,
    '@media (min-width: 380px)': {
      top: 12,
      right: 10,
      left: 'auto'
    },

    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  clientName: {
    fontSize: '1em',
    marginBottom: theme.spacing(1),
    marginTop: '0.5rem'
  },
  ratesContainer: {
    display: 'block'
  }
}));
