import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { withFormik, FormikProps } from 'formik';
import { object, number, string } from 'yup';
import { sortBy } from 'lodash';
// Components
import { Modal } from '@shared/components/modals';
import { Button, Grid, Paper, FormHelperText } from '@material-ui/core';
import { Select } from '@shared/components/inputs';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker } from '@shared/components/inputs';
// Types
import { IEmployeeRate, IRoleHistoryItem } from '@shared/types';

interface IEmployeeRoleHistoryModalProps {
  initialValues: IRoleHistoryItem | {};
  open: boolean;
  close: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  onSave: (values: IRoleHistoryItem) => void;
  roles: IEmployeeRate[];
  employeeId: number;
  roleHistory: IRoleHistoryItem[];
  currentIndex: number;
}

const EmployeeRoleHistoryModal: FC<IEmployeeRoleHistoryModalProps & FormikProps<IRoleHistoryItem>> = ({
  initialValues,
  setFieldValue,
  close,
  onSave,
  open,
  roles,
  handleChange,
  resetForm,
  errors,
  touched,
  values,
  handleSubmit,
  roleHistory,
  currentIndex
}) => {
  const classes = useStyles();

  const getMinDate = () => {
    if (roleHistory.length && currentIndex === -1) {
      // adding a new role, so look at the end or start date of the first history item
      return roleHistory[0].startDate;
    } else if (roleHistory.length && currentIndex > -1 && currentIndex < roleHistory.length - 1) {
      // editing an existing role, so look at the end or start date of the previous history item
      return roleHistory[currentIndex + 1].startDate;
    } else if (roleHistory.length && currentIndex === roleHistory.length - 1) {
      // arbitrary minimum date
      return '1990-01-01';
    }
  };

  return (
    <Modal
      title={currentIndex === -1 ? 'Add Employee Role' : 'Edit Employee Role'}
      onClose={() => close()}
      open={open}
      fullWidth={false}
      actions={
        <>
          <Button color='primary' variant='contained' onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button color='secondary' variant='contained' onClick={() => close()}>
            Cancel
          </Button>
        </>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper elevation={0} square>
            <div className={classes.column}>
              <Select
                className={classes.formControl}
                label='Role'
                id='role'
                value={values.employeeRateId ? values.employeeRateId : 0}
                placeholderItem={{ key: '--', value: '--', label: '--' }}
                onChange={({ target: { value } }) => {
                  if (value) {
                    setFieldValue('employeeRateId', value);
                  }
                }}
                options={sortBy(
                  // filter out inactive when working with new only, otherwise take them all
                  roles.filter(x => x.isActive || currentIndex !== -1).map(x => ({ key: x.employeeRateId, value: x.employeeRateId, label: x.title })),
                  'label'
                )}
              />
              {Boolean(touched.employeeRateId && errors.employeeRateId) && (
                <FormHelperText>{touched.employeeRateId && errors.employeeRateId}</FormHelperText>
              )}
              <FormControl fullWidth>
                <DatePicker
                  id='date-picker-start'
                  label='Start Date'
                  name='startDate'
                  minDate={getMinDate()}
                  value={values.startDate ? new Date(values.startDate) : null}
                  error={(errors.startDate && touched.startDate) as boolean}
                  helperText={touched.startDate && errors.startDate}
                  onChange={date => {
                    if (date) {
                      setFieldValue('startDate', date.toISOString());
                    }
                  }}
                />
              </FormControl>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Modal>
  );
};

const EmployeeRoleHistoryModalSchema = object().shape({
  employeeRateId: number().nullable().required('Role is required'),
  startDate: string().nullable().required('Start Date is required'),
  endDate: string().nullable()
});

export default withFormik<IEmployeeRoleHistoryModalProps, IRoleHistoryItem>({
  enableReinitialize: true,
  mapPropsToValues: ({ employeeId, initialValues = {} }) => {
    return {
      employeeId: employeeId,
      employeeRateId: null,
      startDate: new Date().toISOString(),
      title: '',
      employeeTitleId: null,
      ...initialValues
    };
  },
  validationSchema: EmployeeRoleHistoryModalSchema,
  handleSubmit: (values, { resetForm, props: { onSave, close } }) => {
    onSave(values);
    close();
    resetForm();
  }
})(EmployeeRoleHistoryModal);

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(1)
    }
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  formControl: {
    width: '100%',
    marginBottom: '1rem'
  },
  delete: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white
  }
}));
